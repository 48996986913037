

















































import './styles/MemberMainInfo.scss';
import './styles/MemberMainInfoAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IUserState } from '@store/modules/user/Interfaces';
import { IProject } from '@store/modules/project/Interfaces';
import { IMemberInfo, IProjectTeamState } from '@store/modules/project-team/interfaces/Interfaces';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSProjectTeam = namespace('storeProjectTeam');

@Component({
    name: 'MemberMainInfo',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class MemberMainInfo extends Vue {
    @Prop({}) memberMainInfo!: any;
    @Prop({}) updatedData!: any;

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSUser.State((state: IUserState) => state.userInfo.id) currentUserUuid!: string;
    @NSUser.Getter('isAdminOnCurrentProject') isAdminOnCurrentProject!: boolean;
    @NSProjectTeam.Getter('deactivatedMembers') deactivatedMembers!: IMemberInfo[];
    @NSProjectTeam.State((state: IProjectTeamState) => state.viewedMember) viewedMember!: IMemberInfo;

    get editProfileAvailable() {
        return this.currentUserUuid === this.viewedMember.uuid
            || this.currentUserUuid === this.projectData.author.uuid
            || this.isAdminOnCurrentProject;
    }

    get roleTitle() {
        if (this.updatedData && this.updatedData.roleObject) {
            return this.updatedData.roleObject.value
        } else {
            return this.memberMainInfo.role.label;
        }
    }

    get companyTitle() {
        if (this.updatedData && this.updatedData.companyObject) {
            return this.updatedData.companyObject.value
        } else {
            return this.memberMainInfo.legalEntity;
        }
    }

    get isUserDeactivated() {
        return this.deactivatedMembers.filter(item => item.uuid === this.viewedMember.uuid).length !== 0;
    }
}
